<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Determine the molecular and empirical formulae for the two molecules shown below:
      </p>

      <v-simple-table style="width: 650px">
        <tbody>
          <tr>
            <td style="width: 14%; align-content: center" />
            <td style="width: 43%; text-align: center">
              <v-img
                :src="structureA"
                class="mt-3 mb-3"
                style="width: 150px; display: block; margin: 0 auto"
              />
            </td>
            <td style="width: 43%; text-align: center">
              <v-img
                :src="structureB"
                class="mt-3 mb-3"
                style="width: 240px; display: block; margin: 0 auto"
              />
            </td>
          </tr>
          <tr>
            <td style="font-weight: bold">Molecular Formula</td>
            <td style="vertical-align: middle">
              <chemical-notation-input
                v-model="inputs.molFormulaA"
                class="mt-4 mb-4"
                :disabled="!allowEditing"
              />
            </td>
            <td style="vertical-align: middle">
              <chemical-notation-input
                v-model="inputs.molFormulaB"
                class="mt-4 mb-4"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
          <tr>
            <td style="font-weight: bold">Empirical Formula</td>
            <td>
              <chemical-notation-input
                v-model="inputs.empFormulaA"
                class="mt-4 mb-4"
                :disabled="!allowEditing"
              />
            </td>
            <td>
              <chemical-notation-input
                v-model="inputs.empFormulaB"
                class="mt-4 mb-4"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput';

export default {
  name: 'Question217',
  components: {
    ChemicalNotationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        molFormulaA: null,
        molFormulaB: null,
        empFormulaA: null,
        empFormulaB: null,
      },
    };
  },
  computed: {
    versionVariableA() {
      return this.taskState.getValueBySymbol('versionVariableA').content;
    },
    structureA() {
      if (this.versionVariableA.value === 1) {
        return '/img/assignments/Si2H4Cl2.png';
      } else if (this.versionVariableA.value === 2) {
        return '/img/assignments/Si2H2Cl4.png';
      } else if (this.versionVariableA.value === 3) {
        return '/img/assignments/Si2H2F2Cl2.png';
      } else if (this.versionVariableA.value === 4) {
        return '/img/assignments/Si2F2Cl2Br2.png';
      } else {
        return '';
      }
    },
    versionVariableB() {
      return this.taskState.getValueBySymbol('versionVariableB').content;
    },
    structureB() {
      if (this.versionVariableB.value === 1) {
        return '/img/assignments/C4H8.png';
      } else if (this.versionVariableB.value === 2) {
        return '/img/assignments/C4H6-1.png';
      } else if (this.versionVariableB.value === 3) {
        return '/img/assignments/C4H6-2.png';
      } else if (this.versionVariableB.value === 4) {
        return '/img/assignments/C4H4.png';
      } else {
        return '';
      }
    },
  },
};
</script>
